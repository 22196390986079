@import "sass-lib";
@media (min-width: 0px) and (max-width: 1279px) {
	.wrapper{ width:940px; padding: 0 80px;  }
	.banner-home{
		h1{ font-size: 39px; line-height: 61px; padding-right:0; }
		p{ padding-right: 0; }
	}
	.btn{
		&.rounded-arr{
		 	padding:0 50px 0 15px; font-size: 14px;
		 	&:after{ right:15px; }
		 	/*&.gr{
				&:after{ background:url(../images/material/btn-arr-gr.png)no-repeat center;  }
				&:hover {color: #66B82E; }
			}
			&.green{
				&:hover {color: #fff;}
			}
			&:before {display: none;}
			&:hover {
				color: inherit;	border-color: #fff;	color: #fff;
				&:before {
					display:none;
				}
				&.gr{
					&:after{ background:url(../images/material/btn-arr-gr.png)no-repeat center;  }
				}
			}*/
		}
	}

	.underconst{
		.logo {padding-top: 60px;}
		h1{ font-size:49px; line-height: 55px;  }
		h5{ margin-bottom: 5px; }
		&:after{
			width:311px; background-size: 100%;
		}
		.wrapper{ width:auto; padding:0 39px; margin: 0 40px;  }
		.text{
			padding-top: 20px;
			/*margin-bottom: 120px;*/
			.wrap-text{
				/*float: none; display: block; width: 100%;
				margin-top: 50px; margin-bottom: 40px;*/
				padding: 0 0 0 31px;
			}
			figure{
				/*float: none; display: block; width: 100%; text-align: center; margin: 0 auto;*/
				img{  }
			}
		}
		/*.end-sec{ margin: 40px -1000px 40px -40px; }
		.logo{ padding-top: 60px; }
		.line-body-sec{ left: -40px; }
		.linesec-medium{ left: -42px; }
		.tow-column{ padding-bottom: 40px; }*/
	}
	header{
		.toggle-mobile{
			display: block; float: right; padding-top: 20px; position: relative;
			.burgermenu{ display: inline-block; float: right; }
			.box-search{
				display: inline-block; float: right; @include afterclear; padding-top: 5px; margin-right: 15px;
				position: relative;
				.search-drop{
	                position: absolute; top: 70px; right: 0; left: -300px;  width: 335px; display: none;
	               height:70px; @include boxShadow(0 0 25px -15px #000);
	               &:before{
	                    content: ''; position: absolute;top: -10px;right: 20px;border-bottom: 10px solid #fff;
	                    border-left: 5px solid transparent;border-right: 5px solid transparent; display: block;
	                    width: 0; height: 0; margin: auto;
	               }
	               input[type='text']{
	                    width: 100%; @include boxSizing(border-box); height: 100%; border:none;
	                    font-size: 16px; color: #646464;  @include borderRadius(10px);
	                }
	            }
			}

			.black{ display: none; }
		}
		.top-mobile{
			display: block; @include afterclear; position: relative; border-bottom: 1px solid #eee;
			padding-bottom: 15px;
			z-index: 5; background: #fff;
			figure{
				float: left; margin-top: 20px; margin-left: 20px;
				img{ width: 200px; }
			 }
			.burgermenu-close{ position: absolute; right: 30px; top: 40px; }
		}
		.right-header{
			position: fixed; right: 0; top: 0; bottom: 0; background: #fff; z-index: 12; width: 600px;
			padding-top: 0; display: none;
			nav{
				position: absolute; width: 100%;
				float: none; height: 100%; overflow: auto;
				top: 0; right: 0;
				z-index: 4;
				/*padding: 40px 20px 50px 40px;*/
				padding: 110px 20px 50px 40px;
				@include boxSizing(border-box);
				>ul{
					/*overflow: auto; */
					padding-right: 15px;
					>li{
						display: block; float: none; padding-bottom: 0;  text-align: right;
						margin:0 0 25px 0;
						a{
							color:#111; font-weight: 600; font-size: 18px;
							&:before{ display:none; }
						}
					}
					ul{
						display: none; position: relative; opacity: 1; visibility: visible;
						top: auto; left: auto; right: auto; @include transform(translateX(0));
						width: 100%; background: none; @include boxShadow(none); padding-top: 15px;
						border: none; @include transition(none);
						&:before{ display:none; }
						li{
							border: none;  padding:5px 0; text-align: right;
							&.active{ border: none; }
							a{ color: #333; }
						}
					}
				}
			}
			.box-search{ display: none; }

		}
		.search-mobile{
			display: block; padding: 0 40px; @include boxSizing(border-box);
			input[type='text']{
				width: 100%; @include boxSizing(border-box); height: 50px; border: none;
				border-bottom: 1px solid #193D6F; font-size: 18px; padding-right: 50px;
				background: url(../images/material/ic-search-black.png)no-repeat center right;
			 }
			 @include placeholder{ font-style: italic; }
		}
		&.float{
			.toggle-mobile{
				.white{ display: none; }
				.black{ display: block; }
			}
			.search{
				.btn-search{
					background: url(../images/material/ic-search-black.png)no-repeat center;
				}
			}
		}

	}
	.overlay-mobile{ background:rgba(0, 0, 0, 0.8); position: fixed;top: 0; left: 0; right: 0;bottom: 0; z-index: 3; display: none;  }
	.line-body{
		left: 27px;
		&:before{ height:80px; }
	}
	.line-body-sec{ left: 27px; }
	.home{
		h4{ font-size: 31px;line-height: 45px; }
		h5{ margin-bottom: 15px; }
		&.about{
			padding: 70px 0 80px;
			.wrapper{
				padding: 0 0 0 80px;
			}
			&:after{ height:415px; width: 415px;  background-size: cover;}
		}

		&.register{
			.wrap-list-formulir{
				.list{ padding-bottom: 45px; }
				a{position: absolute; left: 83px; bottom: 20px; margin: 0;  }
				p{  padding: 0 5px 0 5px; width: 85%; float: right; font-size: 14px; height: auto; overflow: hidden;}
				.num{  }
			}
			.download-for{ font-size: 12px; line-height: 18px; }
		}
		&.news{
			.news-wrap{
				.inner{
					padding:20px 15px;
					p{ font-size: 13px; line-height: 18px; }
				}
				.list{ padding: 0 8px; }
			}
			.wrap-news{
				.btn{ margin-top: 20px; }
			}
		}
		&.register{
			.download-for{ width: 100%; }
		}
	}
	.video-home{
		.thumb-video{ height: 225px; }
	}
	.linesec-medium{ height: 80px; left: 25px; }
	.banner-middle{
		h1{ font-size: 31px; line-height: 36px; padding-right: 0; padding-bottom: 15px;}
		p{ width: 100%; margin-top: 15px;}
		figure{
			img{ height: 350px; object-fit: cover;}
		}
		.desc{ top: 70%; @include transform(translateY(-55%)); }
	}
	.middle{
		min-height: 110px;
		.linesec-medium{ left:-55px; }
	}
	.std-content{
		b{ line-height: 24px; font-size: 16px; margin-bottom: 10px; }
	}
	.input-form{
		label{
			top: 1px; line-height: 16px; font-size: 10px; bottom: auto;  @include boxSizing(border-box);
			padding-top: 15px; right: 1px; background: #fff; @include borderRadius(0 10px 0 0);
		}
		.chk-box{
			label{ padding-top: 0; }
		}
		input[type='text'],
		input[type='email'],
		input[type='number'],
		select,
		.field .row-option{ font-size: 15px; font-weight: 200; padding: 25px 20px 0 20px; }
		@include placeholder{ font-size: 15px; }
		textarea{ padding: 40px 20px 20px 20px; font-size: 15px;}
		.col.number-telp{
			label{
				&:after{ right:auto; left: 0; top:40px; font-size: 15px; line-height: 13px; }
			}
			input[type='text']{ padding-left: 50px; font-size: 16px; padding-top: 30px; }
			input[type='number'] {
				padding-left: 53px;
			}
		}
		.col.textarea {
			label {
				line-height: 16px; top: 1px; padding-bottom: 10px;
			}
		}
		.upload_file {
			label {
				top: 0; padding-top: 0;
			}
		}
		.row-option label{
			padding-top:0;
		}
	}
	.two-box-middle{
		h1{ font-size: 29px; line-height: 38px; }
		h2{ font-size: 25px; line-height: 30px;}
		.left{
			p{ font-size: 16px; line-height: 22px; }
			b{ font-size: 16px; line-height: 22px; }
		}
		.date{ font-size: 55px; }
		.monthyear{ font-size: 20px; }
		&.table{
			.left{
				width: 40%;
			}
			.right{
				vertical-align: middle; width: 60%;
				h5{ font-size: 16px; line-height: 26px; }
				h6{ margin-bottom: 10px; }
				p{ display: none; }
			}
		}
		&.margtop{ margin-top: 25px; }

	}
	.list-download{
		.text-download{
			span{ font-size: 13px; line-height:18px;  }
		}
		/*&:hover{
			background: #fafafa; color: inherit;
			.text-download {
				&:after{ background: url(../images/material/ic-download-gren.png)no-repeat center;}
				span {color: inherit;}
			}
		}*/
	}
	.wrap-acc-middle{
		h3{ font-size: 18px; line-height: 26px; }
		h4{ font-size: 14px; }
		.grid-acc{
			padding: 20px 40px 20px 10px;
		}
		&.faq{
			.grid-acc{
				padding: 10px 20px 10px 0;
				&:after{ width:20px; background-size: 100%; }
			}
			p{ font-size: 15px; line-height: 24px; }
			.content-acc{
				padding: 10px 0 0;
			}
			.list{ margin-bottom: 20px; }
		}
		.img-acc{
			width: 220px; display: inline-block;
			img{ width: 220px;  }
		}
		.inner{ padding: 10px 20px 40px; width: 100%;}
		.text{ width: 70%; padding-left: 15px; }
		.wrap-l-r{
			display: block;
			.left,.right{ display: block; width: 100%; }
		}
		.list-download{display: block; margin-bottom: 25px; }
		.btn-underline{ text-align: left; }

	}
	.btn{
		&.rounded-arr{
			&.back{
				&:after{ @include transform(scaleX(1)); }
			}
		}
	}
	.wrap-input-search{
		input[type='text']{ font-size: 31px; }
		@include placeholder{ font-size: 31px; }
	}
	.result{
		h4{ font-size: 15px; line-height: 25px; }
	}
	.text-search-result{ font-size: 16px; font-weight: bold; color: #888888; }
	.input-form{
		.label-tittle{ font-size: 14px; }
	}
	.middle.landing-detail{ padding-top: 130px; }
	.wrap-acc-middle{
		&.faq{
			.grid-acc{
				&:hover{ border-color:#ddd; }
			}
		}
	}
	footer{
		&:after{ width:80px; background-size: 100%; background-position: bottom; }
		.top{
			padding: 30px 0 50px;
		}
		.logo{ width: 195px; float: none;display: block; margin-bottom: 30px; }
		.sub{
			float: left; width: 100%;
			.sub-footer{ float: left; display: inline-block; }
		}
	}
	.page404 .line-bot{
		min-height: 140px;
		&:after{ height:60px; }
	}
}



@media (min-width: 0px) and (max-width: 1023px) {
	.wrapper{ width: auto; margin: 0 40px; padding: 0 39px;  }
	.line-body{ left: 0; }
	.line-body-sec{ left: 0; }
	.linesec-medium{ left: -2px; }
	.page404{
		.center{ top: 40%; @include transform(translateY(-40%)); }
		.line-bot{
			min-height: 220px;
			&:after{
				height:80px;
			}
		}
	}
	.thankyou-page {
		.center{ top: 40%; @include transform(translateY(-40%)); }
		img {margin-bottom: 50px;}
		h5 {font-size: 30px; margin-bottom: 20px;}
	}
	.btn{
		&.rounded-arr{ line-height: 40px; text-transform: none; padding: 0 55px 0 20px; }
	}
	header{
		height: 70px;
		.logo{ height: 70px; }
	}
	.banner-middle{
		p{ font-size: 14px; line-height: 18px; }
	}
	.home{
		h2{ font-size:27px; line-height: 34px; }
		h5{ font-size: 10px; line-height: 16px; margin-bottom: 10px; }
		&.about{
			padding: 50px 0 25px;
			.wrapper{ padding: 0 40px; }
			&:after{
			 	background: url(../images/material/Ellipse-mobile.png)no-repeat center;
			 	right: -5%; bottom: -15%;
			}
			.tittle-video{ font-size: 12px; }
			.two-box{
				.left,.right{ float: none; width: 100%; display: block; }
				.left{ margin-bottom: 40px; }
			}
		}
		&.register{
			padding: 50px 0;
			.wrap-list-formulir{
				p{ width: 80%; }
			}
			.download-for{ width: 100%; }
			.download-for{
			 	&:after{ width:15px; background-size: cover; }
			}
		}
		&.news{
			padding: 40px 0 50px;
			.news-wrap{
				figure{
					float: left; width: 48%;
					img{ @include borderRadius(0); }
				}
				.inner{
					float: left; width: 48%; border: none; padding: 0; padding: 20px 0 10px 10px;
					p{ height: 56px; padding-top: 10px; }
				}
				.list{
					float: none; display: block; width: 100%; border: 1px solid #E8E8E8;
					@include afterclear; padding: 0; margin-bottom: 20px; overflow: hidden;
					@include borderRadius(10px);
				}
			}
			.row-date{ padding-bottom: 10px;  }
		}
	}

	.two-box-middle{
		margin-bottom: 0;
		.left,.right{ width: 100%; float: none; }
		&.detail{
			.left{ float: left; }
			.right{ float: right; }
		}
	}
	.video-home{
		.thumb-video{ height:370px;  }
	}
	.middle{
		.linesec-medium{ left: -41px; }
	}
	.end-sec{
		margin: 40px -1000px 40px -39px;
	}
	.share-detail{
		width: 80px;
		a{ margin: 20px 0; }
	}
	.input-form{
		.col.number-telp{
			label{
				&:after{ right:auto; left: 0; top:40px; font-size: 16px; }
			}
			input[type='number']{ padding-left: 50px; font-size: 16px; }
		}
		.option-form{
			input[type='radio']{
			}
			span{ width:150px;  }

		}
		input[type='file']{ padding: 0px 20px 0 20px; }
		.fileinput{
			height: 60px !important;
			span{ left: 20px; font-size: 16px;  line-height: 20px;top: auto; bottom: 10px;}
		}
		.upload_file{
			label{  top: 0; bottom: auto; line-height: 20px; padding-top: 10px; }
		}
	}
	.dashed{ margin: 20px 0; }
	footer{
		.wrapper{ padding: 0; }
		.sub{
			.sub-footer{
				width: 25%;
				&:last-child{
					a{  }
				}
			}
		}

	}

	.underconst{
		height: auto;
		.text{
			position: relative; margin-bottom: 120px;
			.wrap-text{
				float: none; display: block; width: 100%;
				margin-top: 50px; margin-bottom: 40px;
			}
			figure{
				float: none; display: block; width: 100%; text-align: center; margin: 0 auto;
				position: relative;
				img {
					position: relative;
				}
			}
		}
		.end-sec{
			margin: 40px -1000px 40px -40px; position: relative; width: auto;
			bottom: 0;
		}
		.logo{ padding-top: 60px; }
		.line-body-sec{ left: -40px; }
		.linesec-medium{ left: -42px; }
		.tow-column{ padding-bottom: 40px; position: relative; }
	}
	footer{
		.middle-footer{
			.wrapper{
				&:after{
					left:0;right: 0px;
				}
			}
			.slider-footer{
				margin: 0px;
			}
		}
	}

}